<template>
  <main>
    <CoursResultSect></CoursResultSect>
  </main>
  <FooterComponent></FooterComponent>
</template>

<script>
import CoursResultSect from "@/components/cours/result/section/CoursResultSect.vue";
import FooterComponent from "@/components/footer/FooterComponent.vue";
export default ({
  components: {
    CoursResultSect,
    FooterComponent
  }
})
</script>

<style lang="scss">
@import '../../utils/computer/components.scss';

main {
  position: relative;
}

</style>