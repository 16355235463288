<template>
  <main>
    <AccountSect></AccountSect>
  </main>
</template>

<script>
import AccountSect from "@/components/account/page/AccountSect.vue";
export default ({
  components: {AccountSect}
})
</script>

<style lang="scss">
@import '../../utils/computer/components';

main{
  overflow: hidden;
}

@media only screen and (max-width: 1024px) {
  main {}
}

</style>