<template>
  <AdminHeader :selected="selectedPage" @selectPage="handleSelectPage"></AdminHeader>
    <AdminUser :adminUser="selectedPage === 0"></AdminUser>
    <AdminCours :adminUser="selectedPage === 1"></AdminCours>
  <AdminReport :adminUser="selectedPage === 2"></AdminReport>
    <AdminManage :adminUser="selectedPage === 6"></AdminManage>
    <AdminStats :adminUser="selectedPage === 7"></AdminStats>
</template>

<script>
import AdminHeader from "@/components/admin/header/AdminHeader.vue";
import AdminUser from "@/components/admin/users/AdminUser.vue";
import AdminCours from "@/components/admin/cours/AdminCours.vue";
import AdminManage from "@/components/admin/manage/AdminManage.vue";
import AdminStats from "@/components/admin/stats/AdminStats.vue";
import AdminReport from "@/components/admin/report/AdminReport.vue";
export default {
  components: {
    AdminHeader,
    AdminUser,
    AdminCours,
    AdminManage,
    AdminStats,
    AdminReport
  },
  data() {
    return {
      selectedPage: 0,
    };
  },
  methods: {
    handleSelectPage(index) {
      this.selectedPage = index;
    },
  },
}
</script>

<style lang="scss">
</style>