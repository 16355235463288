<template>
  <main>
    <AddSect></AddSect>
  </main>
</template>

<script>
import AddSect from "@/components/adding/section/AddSect.vue";
export default ({
  components: {
    AddSect
  }
})
</script>

<style lang="scss">
@import '../../utils/computer/components.scss';

main {
}

</style>