<template>
  <main>
    <AdminSect></AdminSect>
  </main>
</template>

<script>
import AdminSect from "@/components/admin/section/AdminSect.vue";
export default ({
  components: {
    AdminSect,
  }
})
</script>

<style lang="scss">
@import '../../utils/computer/components.scss';

main {
  overflow: hidden;
}

</style>