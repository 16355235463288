<template>
  <div v-if="reportModalVisible" class="overlay" ref="overlay" @click="closeReportModal">
  </div>
  <div v-if="reportModalVisible" ref="reportModal" class="report-modal">
    <div class="modal-content">
      <h2>Signaler un problème</h2>
      <textarea id="textarea" v-model="message" @input="updateCharacterCount"
                placeholder="Entrez votre message ici"></textarea>
      <div class="character-counter">{{ remainingCharacters }}/255</div>
      <div class="modal-buttons">
        <div class="btn btn-primary pointer" @click="report">
          <div>
            <div>Envoyer</div>
          </div>
        </div>
        <div class="btn btn-secondary pointer" @click="closeReportModal">
          <div>
            <div>Annuler</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="reportValide" ref="valideReport" class="valide-report">
    <div class="valide-report-content">
      <h4>{{modalMessage}}</h4>
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import anime from "animejs";
import axios from "axios";

export default {
  props: {
    reportModalVisible: Boolean,
    idCours: Number
  },
  data() {
    return {
      message: '',
      maxCharacters: 255,
      remainingCharacters: 255,
      reportValide: false,
      modalMessage: 'Merci pour votre signalement, nous allons traiter votre demande dans les plus brefs délais !'
    };
  },
  methods: {
    report() {
      if (this.remainingCharacters <= 0) {
        this.modalMessage = "Votre message contient trop de caractère !"
        this.showModalInfo()
        return
      } else if(this.remainingCharacters == 255){
        this.modalMessage = "Veuillez saisir votre message !"
        this.showModalInfo()
        return
      }
      axios.post('https://delmoo.fr:5000/report', {
        message: this.message,
        id: this.idCours
      }, {
        withCredentials: true,
        validateStatus: function (status) {
          return status === 200 || status === 500 || status === 401;
        }
      }).then(() => {
        this.showModalInfo();
      })
    },
    showModalInfo(){
      this.reportValide = true;
      document.querySelector('.report-modal').style.opacity = '.5';
      this.$nextTick(() => {
        anime({
          targets: this.$refs.valideReport,
          translateX: ["500%", "0"],
          easing: 'easeOutElastic(.5, .3)',
          duration: 500,
          complete: () => {
            setTimeout(() => {
              this.$router.push('/cours').then(() => {
                window.location.reload()
              })
            }, 1000);
          },
        });
      })
    },
    updateCharacterCount() {
      this.remainingCharacters = this.maxCharacters - this.message.length;
      if (this.remainingCharacters <= 0) {
        document.getElementById('textarea').style.border = '2px solid #E42618';
      }
    },
    closeReportModal() {
      anime({
        targets: this.$refs.overlay,
        opacity: 0,
      });
      anime({
        targets: this.$refs.reportModal,
        translateX: ["0", "500%"],
        duration: 500,
        complete: () => {
          this.$emit('update:reportModalVisible', false);
        },
      });
    },
  }
}
</script>

<style lang="scss">
@import "../../utils/computer/components.scss";

.overlay {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  z-index: 999;
  transition: opacity 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-modal {
  position: fixed;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-content {
    background: $primary-white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;

    h2 {
      margin-bottom: 10px;
    }

    textarea {
      width: 100%;
      height: 100px;
      margin-bottom: 10px;
      outline: none;
    }

    .character-counter {
      font-size: .7rem;
      text-align: right;
      margin-bottom: 1rem;
      margin-top: -.5rem;
    }

    .modal-buttons {
      display: flex;
      justify-content: space-around;
    }
  }
}

.valide-report {
  position: fixed;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;

  .valide-report-content {
    background: $primary-white;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
}
</style>