import {createApp} from 'vue'
import App from './App.vue'

import VueCookies from 'vue-cookies';

import router from './router'
import axios from "axios";


axios.get("https://delmoo.fr:5000/connect", {
    withCredentials: true,
    validateStatus: function (status) {
        return status === 401 || status === 200;
    }
}).then((res) => {
    const isAuthenticated = res.status === 200;

    // Passez l'information à App.vue
    createApp(App, {
        isAuthenticated: isAuthenticated,
    })
        .use(router)
        .use(VueCookies)
        .mount('#app');
})

// const app = createApp(App);
// app.use(VueCookies);
// app.use(router);
//
//
// app.mount('#app');
