<template>
  <main class="main-soon">
    <SoonSect></SoonSect>
  </main>
  <FooterComponent></FooterComponent>
</template>

<script>

import {defineComponent} from "vue";
import SoonSect from "@/components/soon/SoonSect.vue";
import FooterComponent from "@/components/footer/FooterComponent.vue";

export default defineComponent({
  components: {FooterComponent, SoonSect}
})
</script>

<style lang="scss">

</style>