<template>
  <div class="overlay" ref="overlay" v-if="errorModalVisible" @click="closeErrorModal">

  </div>
  <div v-if="errorModalVisible" class="error-modal" ref="errorModal">
    <div class="error-header">
      <h4>Error !</h4>
      <div class="close-modal" @click="closeErrorModal">
        <IonIcon name="close-outline"></IonIcon>
      </div>
    </div>
    <div class="error-content">
      <p>{{ message }}</p>
      <h6>If the error persists, please contact the administrator</h6>
    </div>
  </div>
</template>

<script>
import {IonIcon} from '@ionic/vue';
import anime from "animejs";
export default {

  components: {
    IonIcon
  },
  data() {
    return {
      errorMessage: '',
    };
  },
  props: {
    message: String,
    errorModalVisible: Boolean
  },
  methods: {
    closeErrorModal() {
      anime({
        targets: this.$refs.overlay,
        opacity: 0,
      });
      anime({
        targets: this.$refs.errorModal,
        translateX: ["0", "500%"],
        duration: 500,
        complete: () => {
          this.$emit('update:errorModalVisible', false);
        },
      });
    },
  }

}

</script>

<style lang="scss">
@import "../../utils/computer/components.scss";

.overlay {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-modal {
  position: fixed;
  width: 300px;
  background-color: $primary-white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  overflow: hidden;
  z-index: 1000;


  .error-header {
    background-color: $primary-red;
    color: #fff;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin: 0;
      font-size: 18px;
    }

    .close-modal {
      cursor: pointer;

      ion-icon {
        font-size: 20px;
        color: #fff;
      }
    }
  }

  .error-content {
    padding: 20px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    h6 {
      margin-top: 10px;
      font-size: 14px;
      color: #777;
    }

  }
}


</style>