<template>
  <div class="overlay" ref="overlay" v-if="isTypeVisible" @click="closeType"></div>
  <div v-if="isTypeVisible" class="author-stat" ref="author-stat">
    <canvas id="myChart"></canvas>
  </div>
</template>

<script>
import {Chart} from 'chart.js/auto';
import axios from "axios";

export default {
  props: {
    isTypeVisible: Boolean
  },
  data() {
    return {
      chartData: [],
    }
  },
  methods: {
    fetchChartData() {
      axios.get('https://delmoo.fr:5000/admin/type', {
        withCredentials: true,
        validateStatus: function (status) {
          return status === 200 || status === 500 || status === 401;
        }
      }).then(response => {
        if (response.status == 401 || response.status == 500) {
          this.closeAuthor();
          return;
        }
        this.chartData = response.data.json;
        this.renderChart();
      }).catch(error => {
        console.error('Erreur lors de la récupération des données', error);
      });
    },
    renderChart() {
      const ctx = document.getElementById('myChart').getContext('2d');
      let data = this.chartData;
      const labels = data.map(entry => `${entry.type}`);
      const downloadData = data.map(entry => entry.download);
      const seeData = data.map(entry => entry.see);

      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Download',
              data: downloadData,
              backgroundColor: 'rgba(182, 205, 232, 0.5)',
              barPercentage: 0.45,
            },
            {
              label: 'Views',
              data: seeData,
              backgroundColor: 'rgba(147, 184, 147, 0.5)',
              barPercentage: 0.45,
            },
          ],
        },
        options: {
          scales: {
            x: {
              stacked: false,
            },
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  const label = labels[context.dataIndex];
                  return `${label}: ${context.dataset.label} - ${context.parsed.y}`;
                },
              },
            },
          },
        },
      });
    },
    closeType() {
      this.$refs.overlay.style.opacity = '0';
      this.$emit('update:isTypeVisible', false);
    }
  },
  watch: {
    isTypeVisible(newValue) {
      if (newValue) {
        this.fetchChartData()
      }
    },
  }
}

</script>

<style lang="scss">
@import '../../../../utils/computer/components.scss';

.overlay {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  z-index: 999;
  transition: opacity 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.author-stat {
  position: fixed;
  width: 400pt;
  height: 200pt;
  padding: 2rem;
  background-color: $primary-white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  overflow: hidden;
  z-index: 1000;
}
</style>