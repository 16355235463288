<template>
  <main class="main-login">
    <LogSect></LogSect>
  </main>
</template>

<script>
import LogSect from "@/components/login/section/LogSect.vue";
export default {
  name: 'App',
  components: {
    LogSect
  }
}

</script>

<style lang='scss'>

.main-login {
  background-image: url("../assets/login1.svg");
  background-size: cover;
  overflow: hidden;
}
</style>