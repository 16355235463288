<template>
  <div class="file">
    <div class="file-metadata">
      <div class="file-type btn btn-cours btn-no-hover file-type-cm">
        <div>
          <div>{{ type }}</div>
        </div>
      </div>
      <div class="file-date-added">{{ date }}</div>
    </div>
    <h4>{{ matter }}</h4>
    <h5>{{ name }}</h5>
    <h5>{{ teacher }} • {{ author }}</h5>
    <div class="problem-signal">
      <h6 @click="showReportModal(id)">
        Signaler un problème
      </h6>
      <IonIcon name="alert-circle-outline" class="information" @mouseover="showTooltip"
               @mouseout="hideTooltip"></IonIcon>
      <div class="tooltip" v-if="tooltipVisible">
        <p>{{ tooltipText }}</p>
      </div>
    </div>
    <div class="file-cta">
      <a :href="getFile(path)" target="_blank">
        <div class="btn btn-primary btn-icon-forward pointer">
          <div>
            <div>Voir</div>
            <IonIcon name="eye"></IonIcon>
          </div>
        </div>
      </a>
      <div class="btn btn-cours btn-icon pointer" @click="downloadFile(path, name)">
        <div>
          <div>
            <IonIcon name="cloud-download"></IonIcon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ErrorModal :errorModalVisible="errorModalVisible" :message="errorMessage"
              @update:errorModalVisible="updateErrorModal"></ErrorModal>
</template>

<script>
import {IonIcon} from '@ionic/vue';
import axios from "axios";
import ErrorModal from "@/components/modal/ErrorModal.vue";
import anime from "animejs";

export default ({
  components: {
    IonIcon,
    ErrorModal
  },
  emits: ['show-report-modal'],
  methods: {
    showTooltip() {
      this.tooltipVisible = true;
    },
    hideTooltip() {
      this.tooltipVisible = false;
    },
    getFile(path) {
      return `https://delmoo.fr:5000/cours/open?file=${path}`;
    },
    downloadFile(coursPath, name) {
      axios.post('https://delmoo.fr:5000/cours/download', {
        filePath: coursPath
      }, {
        responseType: 'blob',
        validateStatus: function (status) {
          return status === 200 || status === 500 || status === 403;
        }
      })
          .then((response) => {
            const blob = new Blob([response.data], {type: response.headers['content-type']});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            this.showErrorModal(error.message);
          });
    },
    showErrorModal(message) {
      this.errorMessage = message;
      this.errorModalVisible = true;
      this.$nextTick(() => {
        anime({
          targets: this.$refs.overlay,
          opacity: 1,
          duration: 500,
        });
        anime({
          targets: ".error-modal",
          translateX: ["200%", "0%"],
          easing: 'easeOutElastic(.5, .3)',
          duration: 500
        });
      })
    },
    updateErrorModal(value) {
      this.errorModalVisible = value;
    },
    showReportModal(id) {
      this.$emit("show-report-modal", id);
    },
  },
  data() {
    return {
      errorModalVisible: false,
      errorMessage: "",
      tooltipVisible: false,
      tooltipText: "Vous pouvez nous signaler une erreur dans le cours si vous en trouvez",
    }
  },
  props: {
    type: String,
    matter: String,
    name: String,
    date: Number,
    path: String,
    teacher: String,
    author: String,
    id: Number
  },


})
</script>

<style lang="scss">
@import "../../../../utils/computer/components.scss";

.tooltip {
  position: absolute;
  background-color: $primary-black;
  color: $primary-white;
  padding: 8px;
  border-radius: 4px;
  z-index: 999;
  bottom: 100%;
  left: 100%;
  transform: translateX(-50%);
  display: block;
  width: 10rem;
  font-size: $small-text;
  p {
    margin: 0;
  }
}

.problem-signal:hover .tooltip {
  //display: block;
}

.file {
  position: relative;
  margin-right: 3%;
  margin-bottom: 3%;
  padding: 1.5rem;
  border-radius: $half-round;
  width: 22%;
  min-width: 170px;

  // Effet Glass Morphism
  background: rgba($color: $terciary-white, $alpha: .5); // rgba(35,35,35,0.5)
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba($color: $terciary-white, $alpha: .25); //rgba(35,35,35,0.25)

  // Effet d'ombre
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  .file-metadata {
    display: flex;
    justify-content: space-between;

    .file-type {
      display: inline-block;

      > div {
        padding: .4rem .6rem;

        > div {
          font-size: $small-text;
          color: $primary-white;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }

    .file-date-added {
      padding: .4rem .6rem;
      font-size: $small-text;
      color: $primary-white;
      font-weight: 600;
    }
  }

  h4 {
    display: block;
    font-size: $h5-text;
    color: $primary-white;
    text-align: center;
    font-weight: 700;
    margin: 1.2rem 0 .4rem 0;
    width: 100%;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  h5 {
    font-size: $p-text;
    font-weight: 600;
    margin: 0;
    margin-bottom: 1rem;
    color: $primary-white;
    text-align: center;
  }

  .problem-signal {
    position: relative;

    margin: 0;
    margin-bottom: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;


    h6 {
      margin: 0;
      font-size: $small-text;
      cursor: pointer;
      color: $primary-red;
      font-weight: 600;
      text-align: center;
      overflow: visible;

      &::after {
        position: absolute;
        content: "";
        height: 2px;
        width: 0%;
        top: 95%;
        left: 45%;
        transform: translateX(-50%);
        background-color: $primary-red;
        transition: 0.3s;
      }

      &:hover::after {
        width: 45%
      }
    }

    .information {
      margin-left: .8rem;
      font-size: $h5-text;
      visibility: visible;
      cursor: pointer;
      position: relative;
    }


  }

  .file-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.4rem;

    .btn {
      margin-right: 1rem;

      > div {
        display: flex;
        align-items: center;
        padding: 0.55rem 0.9rem;

        ion-icon {
          font-size: $h6-text;
        }
      }

      &.btn-icon-forward {
        margin-left: 1rem;
      }

      &.btn-icon {
        > div {
          padding: 0.82rem 0.9rem 0.28rem 0.9rem;
        }
      }

    }
  }
}
</style>