<template>
  <div class="overlay" ref="overlay" v-if="addingModalVisible"></div>
  <div class="modal-overlay" v-if="addingModalVisible">
    <div class="modal">
      <div class="modal-header">
        <h2>En cours d'analyse</h2>
      </div>
      <div class="modal-body">
        <p>Le cours est en train d'être analysé par l'antivirus, veuillez patienter...</p>
        <div class="loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      errorMessage: '',
    };
  },
  props: {
    message: String,
    addingModalVisible: Boolean
  },

}

</script>

<style lang="scss">
@import "../../utils/computer/components.scss";

.overlay {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: $primary-white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  width: 300px;
}

.modal-header {
  background: $primary-red;
  color: #fff;
  padding: 10px;
  text-align: center;
}

.modal-header h2 {
  margin: 0;
}

.modal-body {
  padding: 20px;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid $primary-red;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


</style>