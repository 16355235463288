<template>
<section id="sect14">
  <div class="admin-stats-group">
    <div class="btn pointer btn-cours btn-icon-forward btn-admin-choice btn-primary" @click="showAuthor">
      <div>
        <div>Auteur</div>
        <IonIcon name="person-outline"></IonIcon>
      </div>
    </div>
    <div class="btn pointer btn-cours btn-icon-forward btn-admin-choice btn-primary" @click="showMatter">
      <div>
        <div>Matière</div>
        <IonIcon name="library-outline"></IonIcon>
      </div>
    </div>
    <div class="btn pointer btn-cours btn-icon-forward btn-admin-choice btn-primary" @click="showType">
      <div>
        <div>Type</div>
        <IonIcon name="options-outline"></IonIcon>
      </div>
    </div>
    <div class="btn pointer btn-cours btn-icon-forward btn-admin-choice btn-primary" @click="showPromo">
      <div>
        <div>Promotion</div>
        <IonIcon name="alarm-outline"></IonIcon>
      </div>
    </div>
  </div>
  <AuthorStats :isAuthorVisible="isAuthorVisible" @update:isAuthorVisible="updateStatAuthor"></AuthorStats>
  <MatterStats :isMatterVisible="isMatterVisible" @update:isMatterVisible="updateStatMatter"></MatterStats>
  <TypeStats :isTypeVisible="isTypeVisible" @update:isTypeVisible="updateStatType"></TypeStats>
  <PromotionStats :isPromoVisible="isPromoVisible" @update:isPromoVisible="updateStatPromo"></PromotionStats>
</section>
</template>

<script>
import {IonIcon} from "@ionic/vue";
import AuthorStats from "@/components/admin/stats/cards/AuthorStats.vue";
import MatterStats from "@/components/admin/stats/cards/MatterStats.vue";
import TypeStats from "@/components/admin/stats/cards/TypeStats.vue";
import PromotionStats from "@/components/admin/stats/cards/PromotionStats.vue";
import anime from "animejs";

export default {
  components: {IonIcon, AuthorStats, MatterStats, TypeStats, PromotionStats},
  props: {
    adminUser: Boolean
  },
  data(){
    return {
      isAuthorVisible: false,
      isMatterVisible: false,
      isTypeVisible: false,
      isPromoVisible: false
    }
  },
  methods: {
    showMatter(){
      this.isMatterVisible = true;
      this.$nextTick(() => {
        anime({
          targets: this.$refs.overlay,
          opacity: 1,
          duration: 500,
        });
      });
    },
    showAuthor(){
      this.isAuthorVisible = true;
      this.$nextTick(() => {
        anime({
          targets: this.$refs.overlay,
          opacity: 1,
          duration: 500,
        });
      });
    },
    showType(){
      this.isTypeVisible = true;
      this.$nextTick(() => {
        anime({
          targets: this.$refs.overlay,
          opacity: 1,
          duration: 500,
        });
      });
    },
    showPromo(){
      this.isPromoVisible = true;
      this.$nextTick(() => {
        anime({
          targets: this.$refs.overlay,
          opacity: 1,
          duration: 500,
        });
      });
    },
    updateStatMatter(value) {
      this.isMatterVisible = value;
    },
    updateStatPromo(value) {
      this.isPromoVisible = value;
    },
    updateStatType(value) {
      this.isTypeVisible = value;
    },
    updateStatAuthor(value) {
      this.isAuthorVisible = value;
    },
    updateSectionVisibility() {
      if (!this.isAdminUser) {
        document.getElementById("sect14").style.display = "none";
      } else {
        document.getElementById("sect14").style.display = "flex";
      }
    },
  },
  mounted() {
    this.updateSectionVisibility();
    const screenHeight = window.innerHeight;
    document.querySelector('#sect14').style.height = screenHeight + "px";
  },
  watch: {
    adminUser(newValue) {
      this.isAdminUser = newValue;
      this.updateSectionVisibility();

    },
  },
}
</script>

<style lang="scss">
@import "../../../utils/computer/variables.scss";
#sect14 {
  position: relative;
  overflow: hidden;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 101%;
    bottom: -.5rem;
    background-image: url("../../../assets/login1.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: 50%;
    left: 50%;
    transform: translateX(-50%);
  }

  .admin-stats-group {
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    display: flex;
    align-items: center;
    max-height: 28rem;

    // Effet Glass Morphism
    background: rgba($color: $terciary-white, $alpha: .5); // rgba(35,35,35,0.5)
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 1px solid rgba($color: $terciary-white, $alpha: .25); //rgba(35,35,35,0.25)

    // Effet d'ombre
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    border-radius: $half-round;
    padding: 2.4rem 3rem 2rem 3rem;

    .btn{
      margin-right: 5rem;
    }

  }
}
</style>