<template>
  <section id="sect13">
    <div class="admin-manage-group">
      <h2>Cours en cours de validation</h2>
      <div class="filter">
        <select v-model="sortOrder" @change="applyFilters">
          <option value="default">Selectionnez un filtre</option>
          <option value="date">Du plus récent au plus vieux</option>
          <option value="report">Du plus report au moins report</option>
          <option value="author">Grouper par auteur</option>
        </select>
        <select v-model="matter" @change="applyFilters">
          <option value="default">Trier par matière</option>
          <option v-for="(matter, index) in currentFilterMatter" :key="index" :value="matter"> {{matter}}</option>
        </select>
        <select v-model="promotion" @change="applyFilters">
          <option value="default">Trier par promotion</option>
          <option value="1">1A</option>
          <option value="2">2A</option>
          <option value="mt">MT</option>
          <option value="me">ME</option>
          <option value="gcb">GCB</option>
          <option value="iia">IIA</option>
          <option value="gi">GI</option>
        </select>
        <select v-model="type" @change="applyFilters">
          <option value="default">Trier par type</option>
          <option value="CM">CM</option>
          <option value="TD">TD</option>
          <option value="Fiche">Fiche</option>
        </select>
      </div>
      <table>
        <thead>
        <tr>
          <th>Nom</th>
          <th>Matière</th>
          <th>Promotion</th>
          <th>Type</th>
          <th>Prof</th>
          <th>Année</th>
          <th>Auteur</th>
          <th>Nb Report</th>
          <th>Validé</th>
          <th>Supprimer</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(cours, index) in coursDataDisplay" :key="index">
          <td>
            <a :href="getFileURL(cours.path)" target="_blank">
              <label for="cours-name" data-tooltip="Télécharger">{{ cours.name }}</label>
            </a>
            <input type="text" v-model="cours.name" name="cours-name"/>
          </td>
          <td>
            <span>
              {{ cours.matter }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <select v-model="cours.matter" v-if="cours.promotion == '1'">
              <option v-for="(matter, index) in first" :key="index" :value="matter">{{ matter }}</option>
            </select>
            <select v-model="cours.matter" v-if="cours.promotion == '2'">
              <option v-for="(matter, index) in second" :key="index" :value="matter">{{ matter }}</option>
            </select>
            <select v-model="cours.matter" v-if="cours.promotion == 'MT'">
              <option v-for="(matter, index) in mt" :key="index" :value="matter">{{ matter }}</option>
            </select>
            <select v-model="cours.matter" v-if="cours.promotion == 'ME'">
              <option v-for="(matter, index) in me" :key="index" :value="matter">{{ matter }}</option>
            </select>
            <select v-model="cours.matter" v-if="cours.promotion == 'GCB'">
              <option v-for="(matter, index) in gcb" :key="index" :value="matter">{{ matter }}</option>
            </select>
            <select v-model="cours.matter" v-if="cours.promotion == 'GI'">
              <option v-for="(matter, index) in gi" :key="index" :value="matter">{{ matter }}</option>
            </select>
            <select v-model="cours.matter" v-if="cours.promotion == 'IIA'">
              <option v-for="(matter, index) in iia" :key="index" :value="matter">{{ matter }}</option>
            </select>
          </td>
          <td>
            <span>
              {{ cours.promotion }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <select v-model="cours.promotion">
              <option value="1">1A</option>
              <option value="2">2A</option>
              <option value="MT">MT</option>
              <option value="ME">ME</option>
              <option value="GCB">GCB</option>
              <option value="GI">GI</option>
              <option value="IIA">IIA</option>
            </select>
          </td>
          <td>
            <span>
              {{ cours.type }}
            </span>
            <select v-model="cours.type">
              <option value="CM">CM</option>
              <option value="TD">TD</option>
              <option value="Fiche">Fiche</option>
            </select>
          </td>
          <td>
            <label for="teacher-name">{{ cours.teacher }}</label>
            <input type="text" v-model="cours.teacher" name="teacher-name"/>
          </td>
          <td>
            <label for="year-name">{{ cours.year }}</label>
            <input type="text" v-model="cours.year" name="year-name"/>
          </td>
          <td>{{ cours.username }}</td>
          <td>{{cours.report}}</td>
          <td>
            <div class="btn pointer btn-cours" @click="valide(index)">
              <div class="valide">
                <IonIcon name="checkmark-outline"></IonIcon>
              </div>
            </div>
          </td>
          <td>
            <div class="btn pointer btn-cours" @click="deleteCours(index)">
              <div class="delete">
                <IonIcon name="trash-outline"></IonIcon>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <ErrorModal :errorModalVisible="errorModalVisible" :message="errorMessage"
                @update:errorModalVisible="updateErrorModal"></ErrorModal>
    <SuccessModal :showModal="showModalLogin"></SuccessModal>
  </section>
</template>

<script>
import {IonIcon} from "@ionic/vue";
import axios from "axios";
import ErrorModal from "@/components/modal/ErrorModal.vue";
import anime from "animejs";
import SuccessModal from "@/components/modal/SuccessModal.vue";

export default {
  components: {
    SuccessModal,
    ErrorModal,
    IonIcon
  },
  data() {
    const first = ["Analyse", "Algèbre", "OMP", "Algo", "Electrostat", "Electrocin", "Mécanique", "Thermo", "CDM", "Proba", "Magnétostatique", "Optique", "Automatique"];
    const second = ["Analyse", "Algèbre", "Algo", "BDR", "Architecture", "Electromag", "PDO", "OptiqueOndulatoire", "Automatique", "ANUM", "Thermo", "CalculFormel", "POO", "GL"];
    const mt = ["TDS", "C/python", "Electronique", "Electrotechnique", "Automatique", "AA", "RDM", "Mécanique", "Procédé", "Modélisation"];
    const iia = ["Mécanique", "MPES", "Electrotechnique", "Signaux", "AnalyseSys", "CalculEmbarqué"]
    const gi = ["TDS", "C/python", "Electronique", "Electrotechnique", "Automatique", "AA", "RDM", "Proba", "Procédé", "Modélisation", "Maintenance"];
    const gcb = ["Plan", "CAO", "GrosOeuvre", "SecondOeuvre", "Economie", "Architecture", "MecaniqueSol", "RDM"];
    const me = ["AA", "RDM", "Procédé", "Capteurs", "Electrotech", "Mecaflu", "TSM", "Conception", "Mécanique", "Proba", "Thermo", "MMC", "ANUM", "Energétique"];
    return {
      currentFilterMatter: [],
      promotion: 'default',
      matter: 'default',
      type: 'default',
      sortOrder: 'default',
      first,
      second,
      mt,
      me,
      gi,
      gcb,
      iia,
      coursDataDisplay: [],
      errorModalVisible: false,
      errorMessage: "",
      showModalLogin: false,
      coursData: []
    }
  },
  props: {
    adminUser: Boolean
  },
  methods: {
    applyFilters() {
      let filteredData = [...this.coursData];

      if (this.sortOrder === 'date') {
        filteredData.sort((a, b) => parseInt(b.year) - parseInt(a.year));
      } else if (this.sortOrder === 'report') {
        filteredData.sort((a, b) => b.report - a.report);
      } else if (this.sortOrder === 'author') {
        filteredData.sort((a, b) => a.username.localeCompare(b.username));
      }

      if (this.matter !== 'default') {
        filteredData = filteredData.filter(cours => cours.matter === this.matter);
      }
      if (this.type !== 'default') {
        filteredData = filteredData.filter(cours => cours.type === this.type);
      }
      if (this.promotion !== 'default') {
        filteredData = filteredData.filter(cours => cours.promotion === this.promotion);
      }
      this.coursDataDisplay = filteredData;
    },
    deleteCours(index) {
      axios.get(`https://delmoo.fr:5000/cours/delete/${this.coursDataDisplay[index].id}`, {
        withCredentials: true,
        validateStatus: function (status) {
          return status === 200 || status === 500 || status === 401;
        }
      }).then((res) => {
        if (res.status == 500 || res.status == 401) {
          this.showErrorModal(res.data.message);
          return;
        } else {
          this.animateModalLogin();
        }
      })
    },
    valide(index) {
      axios.post("https://delmoo.fr:5000/cours/update", {
        id: this.coursDataDisplay[index].id,
        year: this.coursDataDisplay[index].year,
        teacher: this.coursDataDisplay[index].teacher,
        type: this.coursDataDisplay[index].type,
        promotion: this.coursDataDisplay[index].promotion,
        matter: this.coursDataDisplay[index].matter,
        name: this.coursDataDisplay[index].name
      }, {
        withCredentials: true,
        validateStatus: function (status) {
          return status === 201 || status === 500 || status === 409;
        }
      }).then((res) => {
        if (res.status == 500 || res.status == 401){
          this.showErrorModal(res.data.message);
          return;
        }else{
          this.animateModalLogin();
        }
      })
    },
    getFileURL(coursPath) {
      return `https://delmoo.fr:5000/cours/open?file=${coursPath}`;
    },
    animateModalLogin() {
      this.showModalLogin = true;
      anime({
        targets: '.modal-content',
        scale: [0, 1],
        opacity: [0, 1],
        easing: 'easeOutQuad',
        duration: 500,
        delay: 300,
        complete: () => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
      });
    },
    updateSectionVisibility() {
      if (!this.isAdminUser) {
        document.getElementById("sect13").style.display = "none";
      } else {
        document.getElementById("sect13").style.display = "flex";
      }
    },
    showErrorModal(message) {
      this.errorMessage = message;
      this.errorModalVisible = true;
      this.$nextTick(() => {
        anime({
          targets: this.$refs.overlay,
          opacity: 1,
          duration: 500,
        });
        anime({
          targets: ".error-modal",
          translateX: ["200%", "0%"],
          easing: 'easeOutElastic(.5, .3)',
          duration: 500
        });
      })
    },
    updateErrorModal(value) {
      this.errorModalVisible = value;
    },
  },
  mounted() {
    this.updateSectionVisibility();
    const screenHeight = window.innerHeight;
    document.querySelector('#sect13').style.height = screenHeight + "px";

    axios.get("https://delmoo.fr:5000/cours", {
      validateStatus: function (status) {
        return status === 201 || status === 500;
      }
    }).then((res) => {
      if (res.status == 500) {
        this.showErrorModal(res.data.message);
        return;
      } else {
        this.coursData = res.data.json;
        this.coursDataDisplay = this.coursData
      }
    })
  },
  watch: {
    adminUser(newValue) {
      this.isAdminUser = newValue;
      this.updateSectionVisibility();
    },
    promotion(newValue){
      switch (newValue){
        case '1':
          this.currentFilterMatter = this.first;
          break;
        case '2':
          this.currentFilterMatter = this.second;
          break;
        case 'mt':
          this.currentFilterMatter = this.mt;
          break;
        case 'me':
          this.currentFilterMatter = this.me;
          break;
        case 'iia':
          this.currentFilterMatter = this.iia;
          break;
        case 'gi':
          this.currentFilterMatter = this.gi;
          break;
        case 'gcb':
          this.currentFilterMatter = this.gcb;
          break;
        case 'default':
          this.currentFilterMatter = [];
          break;
      }
    }
  },
}
</script>

<style lang="scss">
@import "../../../utils/computer/variables.scss";

#sect13 {
  position: relative;
  overflow: hidden;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 101%;
    bottom: -.5rem;
    background-image: url("../../../assets/login1.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: 50%;
    left: 50%;
    transform: translateX(-50%);
  }

  .admin-manage-group {
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    overflow: scroll;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 28rem;

    // Effet Glass Morphism
    background: rgba($color: $terciary-white, $alpha: .5); // rgba(35,35,35,0.5)
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 1px solid rgba($color: $terciary-white, $alpha: .25); //rgba(35,35,35,0.25)

    // Effet d'ombre
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    border-radius: $half-round;
    padding: 2.4rem 3rem 2rem 3rem;

    .filter{
      display: flex;
      justify-content: space-between;
      width: 100%;
      input, select {
        font-size: $p-text;
        color: $primary-black;
        padding: .4rem 1rem;
        border-radius: $half-round;
        width: 14rem;

        &::placeholder {
          color: $secondary-black;
        }
      }
    }

    table {
      border-collapse: collapse;
      width: 100%;

      th, td {
        text-align: left;
        padding: 2rem;

        input {
          background: rgba($color: $terciary-white, $alpha: .5); // rgba(35,35,35,0.5)
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          border: 1px solid rgba($color: $terciary-white, $alpha: .25); //rgba(35,35,35,0.25)

          // Effet d'ombre
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

          border-radius: $half-round;
          padding: 0.5rem;
          width: 100%;
        }

        form, input, label {

          position: relative;
          display: block;
          outline: none;
        }

        .btn {
          font-size: $h6-text;

          .valide:before {
            background-color: green;
          }
        }

        a {
          label {
            cursor: pointer;

            &::after {
              content: attr(data-tooltip);
              position: absolute;
              bottom: 25px;
              left: 15%;
              transform: translateX(-50%);
              background-color: rgba(249, 243, 241, 1);
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              backdrop-filter: blur(10px);
              color: rgb(75, 75, 75);
              font-weight: bold;
              padding: 5px;
              font-size: 1rem;
              border-radius: 5px;
              opacity: 0;
              transition: opacity 0.3s;
              white-space: nowrap;
              z-index: 999;
            }

            &:hover::after {
              opacity: 1;
            }
          }

          text-decoration: underline;
          color: inherit;
        }
      }

      tbody tr {
        margin-bottom: 10px;
      }
    }
  }
}
</style>