<template>
  <section id="sect15">
    <div class="admin-user-group">
      <h2>Cours en cours de validation</h2>
      <table>
        <thead>
        <tr>
          <th>Nom</th>
          <th>Matière</th>
          <th>Promotion</th>
          <th>Type</th>
          <th>Prof</th>
          <th>Année</th>
          <th>Auteur Cours</th>
          <th>Auteur Report</th>
          <th>Message</th>
          <th>Report</th>
          <th>Validé</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(cours, index) in reportData" :key="index">
          <td>
            <a :href="getFileURL(cours.path)" target="_blank">
              <label for="cours-name" data-tooltip="Télécharger">{{ cours.name }}</label>
            </a>
          </td>
          <td>{{ cours.matter }}</td>
          <td>{{ cours.promotion }}</td>
          <td>{{ cours.type }}</td>
          <td>{{ cours.teacher }}</td>
          <td>{{ cours.year }}</td>
          <td>{{ cours.content_username }}</td>
          <td>{{ cours.report_username }}</td>
          <td>
            <p>{{ cours.message }}</p>
          </td>
          <td>{{ cours.report }}</td>
          <td>
            <div class="btn pointer btn-cours"
                 @click="valideReport(cours.reportId)">
              <div class="valide">
                <IonIcon name="checkmark-outline"></IonIcon>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <ErrorModal :errorModalVisible="errorModalVisible" :message="errorMessage"
                @update:errorModalVisible="updateErrorModal"></ErrorModal>
  </section>
</template>

<script>
import anime from "animejs";
import ErrorModal from "@/components/modal/ErrorModal.vue";
import {IonIcon} from "@ionic/vue";
import axios from "axios";

export default {
  components: {IonIcon, ErrorModal},
  data() {
    return {
      errorModalVisible: false,
      errorMessage: "",
      reportData: []
    }
  },
  props: {
    adminUser: Boolean
  },
  watch: {
    adminUser(newValue) {
      this.isAdminUser = newValue;
      this.updateSectionVisibility();
    },
  },
  methods: {
    valideReport(id) {
      axios.post("https://delmoo.fr:5000/report/valide", {
        id: id
      }, {
        withCredentials: true
      }).then(() => {
        window.location.reload();
      })
    },
    getFileURL(coursPath) {
      return `https://delmoo.fr:5000/cours/open?file=${coursPath}`;
    },
    showErrorModal(message) {
      this.errorMessage = message;
      this.errorModalVisible = true;
      this.$nextTick(() => {
        anime({
          targets: this.$refs.overlay,
          opacity: 1,
          duration: 500,
        });
        anime({
          targets: ".error-modal",
          translateX: ["200%", "0%"],
          easing: 'easeOutElastic(.5, .3)',
          duration: 500
        });
      })
    },
    updateErrorModal(value) {
      this.errorModalVisible = value;
    },
    updateSectionVisibility() {
      if (!this.isAdminUser) {
        document.getElementById("sect15").style.display = "none";
      } else {
        document.getElementById("sect15").style.display = "flex";
      }
    },
  },
  mounted() {
    this.updateSectionVisibility();
    const screenHeight = window.innerHeight;
    document.querySelector('#sect15').style.height = screenHeight + "px";

    axios.get("https://delmoo.fr:5000/admin/report", {
      withCredentials: true,
      validateStatus: function (status) {
        return status === 200 || status === 500 || status === 401;
      }
    }).then((res) => {
      if (res.status == 500 || res.status == 401) {
        this.showErrorModal(res.data.message);
        return;
      } else {
        this.reportData = res.data.json;
      }
    })
  },
}

</script>

<style lang="scss">
@import "../../../utils/computer/components.scss";

#sect15 {
  position: relative;
  overflow: hidden;
  padding: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 101%;
    bottom: -.5rem;
    background-image: url("../../../assets/login1.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: 50%;
    left: 50%;
    transform: translateX(-50%);
  }

  .admin-user-group {
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    overflow: scroll;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 28rem;

    // Effet Glass Morphism
    background: rgba($color: $terciary-white, $alpha: .5); // rgba(35,35,35,0.5)
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 1px solid rgba($color: $terciary-white, $alpha: .25); //rgba(35,35,35,0.25)

    // Effet d'ombre
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    border-radius: $half-round;
    padding: 2.4rem 3rem 2rem 3rem;

    table {
      border-collapse: collapse;
      width: 100%;

      th, td {
        text-align: left;
        padding: 2rem;

        p {
          overflow-wrap: break-word;
          word-wrap: break-word;
          max-width: 10rem;
        }

        input {
          background: rgba($color: $terciary-white, $alpha: .5); // rgba(35,35,35,0.5)
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          border: 1px solid rgba($color: $terciary-white, $alpha: .25); //rgba(35,35,35,0.25)

          // Effet d'ombre
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

          border-radius: $half-round;
          padding: 0.5rem;
          width: 100%;
        }

        form, input, label {

          position: relative;
          display: block;
          outline: none;
        }

        .btn {
          font-size: $h6-text;

          .valide:before {
            background-color: green;
          }

          .refuse:before {
            background-color: red;
          }
        }

        a {
          text-decoration: underline;
          color: inherit;

          label {
            cursor: pointer;

            &::after {
              content: attr(data-tooltip);
              position: absolute;
              bottom: 25px;
              left: 15%;
              transform: translateX(-50%);
              background-color: rgba(249, 243, 241, 1);
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              backdrop-filter: blur(10px);
              color: rgb(75, 75, 75);
              font-weight: bold;
              padding: 5px;
              font-size: 1rem;
              border-radius: 5px;
              opacity: 0;
              transition: opacity 0.3s;
              white-space: nowrap;
              z-index: 999;
            }

            &:hover::after {
              opacity: 1;
            }
          }
        }
      }

      tbody tr {
        margin-bottom: 10px;
      }
    }
  }

}


</style>